import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 11 12">
    <path 
      fill={color}
      d="M3.12109 1.68994V3.9108C3.12109 4.13389 3.3018 4.31459 3.52489 4.31459C3.74797 4.31459 3.92868 4.13389 3.92868 3.9108C3.92868 3.68772 4.10938 3.50701 4.33247 3.50701C4.55555 3.50701 4.73626 3.68772 4.73626 3.9108V4.11271C4.73626 4.33579 4.91697 4.5165 5.14005 4.5165C5.36314 4.5165 5.54384 4.33579 5.54384 4.11271V1.68994H3.12109Z"
    />
    <path 
      fill={color}
      d="M9.78305 0.881592H5.74512V1.68917H9.78307C10.0062 1.68917 10.1869 1.50847 10.1869 1.28538C10.1869 1.0623 10.006 0.881592 9.78305 0.881592Z"
    />
    <path 
      fill={color}
      d="M0.898666 5.32373H7.76318C8.09771 5.32373 8.36887 5.59487 8.36887 5.92943C8.36887 6.26397 8.09774 6.53513 7.76318 6.53513H0.898666C0.564129 6.53513 0.292969 6.26399 0.292969 5.92943C0.292992 5.59489 0.564129 5.32373 0.898666 5.32373Z"
    />
    <path 
      fill={color}
      d="M7.56218 11.7845H1.10146L0.459628 10.6942C-0.342304 9.33164 -0.0739795 7.5927 1.10146 6.53516H7.56218C8.73762 7.5927 9.00595 9.33164 8.20401 10.6942L7.56218 11.7845Z"
    />
    <path 
      fill={color}
      d="M3.92722 0.0744629H4.7348C5.4039 0.0744629 5.9462 0.616762 5.9462 1.28586C5.9462 1.95495 5.4039 2.49725 4.7348 2.49725H3.92722C3.25812 2.49725 2.71582 1.95495 2.71582 1.28586C2.71584 0.616762 3.25814 0.0744629 3.92722 0.0744629Z"
    />
    <path 
      fill={color}
      d="M8.54789 8.15063H0.114626C-0.11635 9.01052 0.00781783 9.92713 0.459066 10.6946L1.1009 11.7848H7.56162L8.20345 10.6946C8.65468 9.92713 8.77885 9.01052 8.54789 8.15063Z"
    />
    <path 
      fill={color}
      d="M4.53367 0.0744629H4.12988V2.49723H4.53367V0.0744629Z"
    />
    <path 
      fill={color}
      d="M3.32227 0.237471V2.33357C3.44703 2.40584 3.58394 2.4549 3.72606 2.47873V0.0922852C3.58373 0.116117 3.44705 0.165176 3.32227 0.237471Z"
    />
    <path 
      fill={color}
      d="M4.93652 0.0922852V2.47871C5.07867 2.45488 5.21555 2.40582 5.34031 2.33355V0.237471C5.21534 0.165176 5.07867 0.116117 4.93652 0.0922852Z"
    />
    <path 
      fill={color}
      d="M7.56232 6.73731H2.11109C1.99964 6.73731 1.90918 6.64686 1.90918 6.5354C1.90918 6.42395 1.99964 6.3335 2.11109 6.3335H7.56232C7.67377 6.3335 7.76422 6.42395 7.76422 6.5354C7.76422 6.64686 7.67377 6.73731 7.56232 6.73731Z"
    />
    <path 
      fill={color}
      d="M8.54774 8.15015H6.73067C6.2877 9.77542 5.13143 11.112 3.58691 11.7843H7.56147L8.2033 10.6941C8.65453 9.92664 8.77869 9.01004 8.54774 8.15015Z"
    />
  </svg>
);
